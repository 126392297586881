import * as React from "react";
import type { HeadFC } from "gatsby";
import styled from "styled-components";

import { Container } from "../components/Grid";
import { Layout } from "../components/Layout";
import { TermsDocs } from "./terms";

const ScMain = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #020203;

  .post-full-content {
    background-color: transparent;
  }
`;

const TCPage = () => {
  React.useEffect(() => {
    const id = "termly-jssdk";
    let tjs: any = document.getElementsByTagName("script")[0];
    let js;

    if (document.getElementById(id)) return;

    js = document.createElement("script");
    js.id = id;
    js.src = "https://app.termly.io/embed-policy.min.js";
    tjs.parentNode.insertBefore(js, tjs);
  }, []);

  return (
    <Layout>
      <ScMain>
        <Container>
          <article className="post-full post no-image page no-image">
            <h1>Disclaimer</h1>
            <div
              name="termly-embed"
              data-id="0ca5e14e-d2dc-4491-be5d-df7a7f446267"
              data-type="iframe"
            ></div>
          </article>
        </Container>
      </ScMain>
    </Layout>
  );
};

export default TCPage;

export const Head: HeadFC = () => <title>Disclaimer</title>;
